<template>
	<div id="archives_details">
		<index>
			<archives-index :ID="id" :YearId="year_id" :CampusId="campus_id"></archives-index>
		</index>
	</div>
</template>

<script>
	import index from '@/components/common/index.vue';
	import archivesIndex from '@/components/archives/index.vue';
	export default {
		components: {
			index,
			archivesIndex
		},
		data() {
			return {
				id:'',
				campus_id:'',
				year_id:''
			}
		},
		mounted() {
			this.id = this.$route.query.id;
			this.year_id = this.$route.query.year_id;
			this.campus_id = this.$route.query.campus_id;
			console.log(555,this.id)
		},
		methods: {
			
		}
	};
</script>

<style>
	
</style>
