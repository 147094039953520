<template>
	<div>
		<el-breadcrumb separator="/">
			<el-breadcrumb-item>教务</el-breadcrumb-item>
			<el-breadcrumb-item to="/archives_index" v-if="!isMy">教师档案</el-breadcrumb-item>
			<el-breadcrumb-item v-if="!isMy"><a style="color: #000000;">教师详情</a></el-breadcrumb-item>
			<el-breadcrumb-item v-if="isMy"><a style="color: #000000;">我的档案</a></el-breadcrumb-item>
		</el-breadcrumb>
		<div class="nav-tab-bar-box u-f-item u-f-jsb">
			<div class="nav-tab-bar u-f-item">
				<div
					class="font_14 nav-tab-item"
					@click="twacherTap(index)"
					:class="index == teacherIndex ? 'active' : ''"
					v-for="(item, index) in teacherMeun"
					:key="index"
				>
					{{ item.name }}
				</div>
			</div>
		</div>
		<transition name="el-fade-in-linear">
			<template v-if="teacherIndex==0">
				<archives @more="more" :Info="info"></archives>
			</template>
			<template v-if="teacherIndex==1">
				<class-schedule></class-schedule>
			</template>
			<template v-if="teacherIndex==2">
				<station :Info="info"></station>
			</template>
			<!-- <template v-if="teacherIndex==3">
				<performance></performance>
			</template> -->
			<template v-if="teacherIndex==3">
				<record></record>
			</template>
			<template v-if="teacherIndex==4">
				<logSheet></logSheet>
			</template>
			<template v-if="teacherIndex==5">
				<Survey></Survey>
			</template>
			<template v-if="teacherIndex==7">
				<evaluation></evaluation>
			</template>
			<template v-if="teacherIndex==8">
				<use-record></use-record>
			</template>
		</transition>
	</div>
</template>

<script>
import archives from '@/components/archives/archives.vue';	
import classSchedule from '@/components/archives/class-schedule.vue';	
import station from '@/components/archives/station.vue';
import performance from '@/components/archives/performance.vue';
import record from '@/components/archives/record.vue';
import logSheet from '@/components/archives/log-sheet.vue';
import evaluation  from '@/components/archives/evaluation .vue';
import Survey  from '@/components/archives/Survey.vue';
import useRecord  from '@/components/archives/use-record.vue';
export default {
	components:{
		archives,
		classSchedule,
		station,
		performance,
		record,
		logSheet,
		evaluation,
		Survey,
		useRecord
	},
	props: {
		
	},
	data() {
		return {
			years:[],
			semester: '',
			semesters: [],
			yearid:'',
			campusid:'',
			teacherMeun: [
				{ name: '档案总览' },
				{ name: '课表' },
				{ name: '岗位工作' },
				{ name: '档案材料' },
				{ name: '过程性记录' },
				// { name: '学生评教' }
			],
			teacherIndex: 0,
			curriculum_time: [],
			attend_day: [],
			courseData: [],
			id:'',
			info:{
				yearid: '',
				semester: ''
			},
			isMy:false
		};
	},
	created() {
		this.id = this.$route.query.id;
		if(this.id==window.localStorage.getItem('id')){
			this.teacherMeun[1].name = '我的课表';
			this.isMy = true;
		}
		if(this.$route.query.index){
			this.teacherIndex = this.$route.query.index;
		}
	},
	mounted() {
		this.getYearList()
	},
	methods: {
		changeYear(e){
			this.yearid = e;
			this.info.yearid = e;
			this.getXQList()
		},
		changeSem(e){
			this.info.semester = e
		},
		// 学年列表
		getYearList(){
			this.$api.setting.getSemesterList().then(res=>{
				if(res.data.code==1){
					this.years = res.data.data;
					if(this.$route.query.year_id!=undefined){
						this.yearid = Number(this.$route.query.year_id);
					}else{
						if( this.years.filter(item=>item.is_default==2).length>0){
							this.yearid = this.years.filter(item=>item.is_default==2)[0].id;
						}else{
							this.yearid = this.years[0].id
						}
					}
					this.info.yearid = this.yearid;
					this.getXQList()
				}
			})
		},
		// 学期列表
		getXQList(){
			this.$api.setting.getXueQiList({year_id: this.yearid}).then(res=>{
				if(res.data.code==1){
					this.semesters = res.data.data;
					if(res.data.data.length){
						if( this.semesters.filter(item=>item.is_default==2).length>0){
							this.semester = this.semesters.filter(item=>item.is_default==2)[0].id;
						}else{
							this.semester = this.semesters[0].id
						}
					}else{
						this.semester = ''
					}	
					this.info.semester = this.semester
				}
			})
		},
		twacherTap(index) {
			this.teacherIndex = index;
		},
		more(index){
			this.teacherIndex = index;
		},
		
	}
};
</script>

<style lang="scss">
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 15px;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.info {
		padding: 30px 30px 0 30px;
		background-color: #ffffff;
		height: 470px;
	}
	.class_table {
		background-color: #ffffff;
		.tables {
			height: 448px;
			.tb {
				overflow-x: hidden;
				overflow-y: auto;
				-ms-overflow-style: none;
				overflow: '-moz-scrollbars-none';
				scrollbar-width: none;
				.active {
					background-color: #eefaf6;
					border:1px solid #3fc586;
				}
			}
			.tb::-webkit-scrollbar {
				display: none;
			}
		}
	}
	.timecard {
		background-color: #ffffff;
		.timecard-list {
			display: flex;
			flex-wrap: wrap;
			.timecard-item {
				width: 33%;
				height: 80px;
				div {
					span {
						font-size: 1.25rem;
						color: #4998ff;
					}
				}
			}
		}
	}
	.station {
		background-color: #ffffff;
		.station-list {
			background-color: #fafafa;
			height: 158px;
			padding: 15px;
			.station-item {
				margin-bottom: 15px;
				.icon-yuandian1 {
					color: #1890ff;
					margin-right: 0.3125rem;
				}
				.tag {
					background-color: #e8eaec;
					border-radius: 1.25rem;
					margin-left: 0.625rem;
					padding: 0.1875rem 0.9375rem;
				}
			}
		}
	}
	.grade {
		background-color: #ffffff;
		.el-table th {
			padding: 5px 0;
		}
	}
	
</style>
