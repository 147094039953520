<template>
	<div class="performance" style="margin-top: 20px;">
		<div class="u-f-item u-f-jsb" style="padding-bottom: 15px;">
			<div class="font_blod" style="color: #5D5D5D;">共7条数据</div>
			<div><el-input placeholder="输入考试名称搜索" size="small"></el-input></div>
		</div>
		<el-table :data="tableData" ref="multipleTable" @selection-change="handleSelectionChange" tooltip-effect="dark" style="width: 100%" :default-sort = "{prop: 'date', order: 'descending'}">
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column prop="id" label="序号" width="150"></el-table-column>
			<el-table-column prop="year" label="学年" width="85"></el-table-column>
			<el-table-column prop="term" label="学期" width="85"></el-table-column>
			<el-table-column prop="exam" label="考试" width="85"></el-table-column>
			<el-table-column prop="subject" label="学科" width="85"></el-table-column>
			<el-table-column prop="grade" label="班级" width="85"></el-table-column>
			<el-table-column prop="score" label="平均分" sortable width="85"></el-table-column>
			<el-table-column prop="B_val" label="B值" sortable width="85"></el-table-column>
			<el-table-column prop="from_both" label="离均率" sortable width="85"></el-table-column>
			<el-table-column prop="contribute" label="贡献率" sortable width="85"></el-table-column>
			<el-table-column prop="average" label="等级平均率" width="85"></el-table-column>
			<el-table-column label="等级人数">
				<el-table-column prop="A" label="A"></el-table-column>
				<el-table-column prop="B" label="B"></el-table-column>
				<el-table-column prop="C" label="C"></el-table-column>
				<el-table-column prop="D" label="D"></el-table-column>
				<el-table-column prop="E" label="E"></el-table-column>
				<el-table-column prop="F" label="F"></el-table-column>
			</el-table-column>
			<el-table-column prop="ranking_50" label="排名前50" width="85"></el-table-column>
			<el-table-column prop="ranking_100" label="排名前100" width="85"></el-table-column>
			<el-table-column prop="ranking_150" label="排名前150" width="85"></el-table-column>
			<el-table-column label="操作" fixed="right" width="85">
				<template slot-scope="scope">
					<el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">移除</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			multipleSelection: [],
			tableData: [
				{
					id:1,
					year:'2020学年',
					term:'上学期',
					exam:'',
					subject:'语文',
					grade:'高二（14）班',
					score:'0.83',
					B_val:'68.38',
					from_both:'3',
					contribute:'3',
					average:'26',
					A:'5',
					B:'9',
					C:'3',
					D:'-',
					E:'-',
					F:'-',
					ranking_50:'-',
					ranking_100:'-0.1',
					ranking_150:'13'
				},
				{
					id:2,
					year:'2020学年',
					term:'上学期',
					exam:'',
					subject:'语文',
					grade:'高二（14）班',
					score:'0.83',
					B_val:'68.38',
					from_both:'3',
					contribute:'3',
					average:'26',
					A:'5',
					B:'9',
					C:'3',
					D:'-',
					E:'-',
					F:'-',
					ranking_50:'-',
					ranking_100:'-0.1',
					ranking_150:'13'
				}
			]
		};
	},
	created() {},
	mounted() {},
	methods: {
		handleSelectionChange(val) {
			this.multipleSelection = val;
		}
	}
};
</script>

<style lang="scss">
.performance {
	background-color: #ffffff;
	height: 75vh;
	margin-top: 20px;
	padding: 15px 20px;
	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
}
</style>
