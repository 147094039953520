<template>
	<div class="evaluation " style="margin-top: 20px;">
		<div class="u-f-item" style="padding-bottom: 15px;">
			<div style="width: 4px;height: 15px;background-color: #007AFF;margin-right: 10px;"></div>
			<div style="color: #5D5D5D;" class="font_blod font_16">2021学年度-上学期</div>
		</div>
		<div class="evaluation-list" v-for="(items,i) in evaluationList">
			<div class="evaluation-item u-f-item u-f-jsb mouse">
				<div class="u-f-item">
					<div>{{items.title}}</div>
					<div class="grade">{{items.grade}}</div>
				</div>
				<div>
					<span class="num">{{items.num}}</span>人评价
					<i :class="items.show?'el-icon-caret-top':'el-icon-caret-bottom'" @click="showTap(i)"></i>
				</div>
			</div>
			<div class="lists" v-if="items.show">
				<div class="item" v-for="(item,index) in 10">
					<div class="u-f-item" style="margin-bottom: 15px;"><div class="num u-f-justify">{{index+1}}</div> 你认为老师上课前是否认真备课？ </div>
					<div class="u-f-item"><div class="u-line-1" style="width: 80px;">A.课程组织资质</div><div style="color: #007AFF;">100%</div></div>
					<div class="u-f-item"><div class="u-line-1" style="width: 80px;">B.课程组织资质</div><div style="color: #007AFF;">50%</div></div>
					<div class="u-f-item"><div class="u-line-1" style="width: 80px;">C.课程组织资质</div><div style="color: #007AFF;">0%</div></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			evaluationList:[
				{
					title:'2020-2021学年度高三级第一学期学情调查',
					grade:'高二(14)班',
					num:10,
					show:false
				},
				{
					title:'2020-2021学年度高三级第一学期学情调查',
					grade:'高二(14)班',
					num:10,
					show:false
				},
				{
					title:'2020-2021学年度高三级第一学期学情调查',
					grade:'高二(14)班',
					num:10,
					show:false
				}
			]
		};
	},
	created() {},
	mounted() {},
	methods: {
		showTap(i){
			this.evaluationList[i].show = !this.evaluationList[i].show;
		}
	}
};
</script>

<style lang="scss">
.evaluation  {
	background-color: #ffffff;
	height: 75vh;
	margin-top: 20px;
	padding: 15px 20px;
	overflow-y: scroll;
	.evaluation-list{
		margin-bottom: 20px;
		.evaluation-item{
			background-color: #F3F3F3;
			padding: 20px;
			color: #5D5D5D;
			.grade{
				color: #329CFF;
				background-color: #D1E9FF;
				font-size: 12px;
				margin-left: 15px;
				padding: 3px 10px;
				border-radius: 3px;
			}
			.num{
				font-size: 26px;
				color: #2594FE;
			}
			.el-icon-caret-bottom,
			.el-icon-caret-top{
				font-size: 20px;
				color: #8D9DA6;
			}
		}
		.lists{
			overflow-y: scroll;
			height: 17.60416667vw;
			padding: 0 20px;
			.item{
				padding: 15px 0;
				border-bottom: 1px solid #F4F5F6;
				.u-f-item{
					padding-bottom: 5px;
					color: #676767;
					.num{
						background-color: #318EF0;
						color: #FFFFFF;
						border-radius: 50%;
						width: 16px;
						height: 16px;
						font-size: 12px;
						margin-right: 10px;
					}
				}
			}
		}
	}
}
</style>
