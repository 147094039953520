<template>
	<div class="station" style="margin-top: 20px;">
		<div class="nav-tab-bar u-f-item">
			<div class="font_14 nav-tab-item" @click="tabTap(index)" :class="index == tabIndex ? 'active' : ''" v-for="(item, index) in tabbars" :key="index">{{ item.name }}</div>
		</div>
		<div class="contents">
			<template v-if="tabIndex == 0">
				<el-row :gutter="20">
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" v-if="group.length">
						<div class="item">
							<div class="u-f-item">
								<img src="/static/image/xin.png" alt="" />
								<span class="font_16 font_blod">级长</span>
							</div>
							<div style="overflow-y: scroll;height: 100px;margin: 10px 0;">
								<div class="u-f-item u-f-jsb list" v-for="(item,index) in group" :key="index">
									<div>
										<i class="iconfont icon-yuandian"></i>
										{{item.year_name}}学年-{{item.semester_name}}
									</div>
									<div>{{item.name}}</div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" v-if="tclass.length">
						<div class="item">
							<div class="u-f-item">
								<img src="/static/image/xiao.png" alt="" />
								<span class="font_16 font_blod">班主任</span>
							</div>
							<div style="overflow-y: scroll;height: 100px;margin: 10px 0;">
								<div class="u-f-item u-f-jsb list" v-for="(item,index) in tclass" :key="index">
									<div>
										<i class="iconfont icon-yuandian"></i>
										{{item.year_name}}学年-{{item.semester_name}}
									</div>
									<div>{{item.name}}</div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" v-if="grade.length">
						<div class="item">
							<div class="u-f-item">
								<img src="/static/image/menu.png" alt="" />
								<span class="font_16 font_blod">年级长</span>
							</div>
							<div style="overflow-y: scroll;height: 100px;margin: 10px 0;">
								<div class="u-f-item u-f-jsb list" v-for="(item,index) in grade" :key="index">
									<div>
										<i class="iconfont icon-yuandian"></i>
										{{item.year_name}}学年-{{item.semester_name}}
									</div>
									<div>{{item.name}}</div>
								</div>
							</div>
						</div>
					</el-col>
					
					<el-empty :image-size="100" v-if="!grade.length&&!group.length&&!tclass.length" style="margin-top: 150px;"></el-empty>
				</el-row>
			</template>
			<template v-if="tabIndex == 1">
				<div class="font_blod" style="padding-bottom: 20px;">共{{eduWoek.length}}条数据</div>
				<el-table :data="eduWoek" height="62vh" :default-sort="{ prop: 'date', order: 'descending' }">
					<el-table-column type="index" label="序号" width="120" align="center"></el-table-column>
					<el-table-column prop="teacher_name" label="姓名" align="center"></el-table-column>
					<el-table-column prop="grade_name" label="年级" align="center"></el-table-column>
					<el-table-column prop="class_name" label="班级" align="center"></el-table-column>
					<el-table-column prop="day_time" label="上课日期" align="center">
						<template slot-scope="scope">
							<div>{{$common.timeToDate(scope.row.day_time,true)}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="attend_day" label="星期" align="center"></el-table-column>
					<el-table-column prop="node" label="节次" align="center"></el-table-column>
					<el-table-column prop="discipline_name" label="学科" align="center"></el-table-column>
					<el-table-column label="课时" sortable align="center">
						<template slot-scope="scope">
							<div>1</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-right" v-if="eduWoek.length">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="page"
						:page-size="limit"
						layout="total, prev, pager, next"
						:total="total4"
					></el-pagination>
				</div>
			</template>
			<template v-if="tabIndex == 4">
				<div class="font_blod" style="padding-bottom: 20px;">共7条数据</div>
				<el-table :data="tableData1" style="width: 100%" :default-sort="{ prop: 'date', order: 'descending' }">
					<el-table-column prop="id" label="序号" width="100"></el-table-column>
					<el-table-column prop="year" label="学年"></el-table-column>
					<el-table-column prop="term" label="学期"></el-table-column>
					<el-table-column prop="name" label="名称"></el-table-column>
					<el-table-column prop="num" label="人数" sortable></el-table-column>
					<el-table-column label="操作" width="100">
						<template slot-scope="scope">
							<el-button @click="handleClick(scope.row)" type="text" size="small">学生名单</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-right">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="currentPage1"
						:page-size="10"
						layout="total, prev, pager, next"
						:total="3"
					></el-pagination>
				</div>
				<el-dialog :title="el_dialog__title" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
					<div class="u-f-right" style="padding: 15px 0;">
						<el-input v-model="key" size="mini" style="width: 260px;margin-right: 10px;" placeholder="输入名称搜索"></el-input>
						<el-button type="primary" size="mini">导出</el-button>
					</div>
					<el-table :data="tableData1" style="width: 100%;" :default-sort="{ prop: 'date', order: 'descending' }">
						<el-table-column prop="id" label="序号"></el-table-column>
						<el-table-column prop="year" label="学籍号"></el-table-column>
						<el-table-column prop="term" label="班级"></el-table-column>
						<el-table-column prop="name" label="姓名"></el-table-column>
					</el-table>
					<span slot="footer" class="dialog-footer">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="currentPage1"
							:page-size="10"
							layout="total, prev, pager, next"
							:total="3"
						></el-pagination>
					</span>
				</el-dialog>
			</template>
			<template v-if="tabIndex == 3">
				<div class="font_blod" style="padding-bottom: 20px;">共7条数据</div>
				<el-table :data="tableData1" style="width: 100%" :default-sort="{ prop: 'date', order: 'descending' }">
					<el-table-column prop="id" label="序号" width="100"></el-table-column>
					<el-table-column prop="year" label="学年" sortable></el-table-column>
					<el-table-column prop="term" label="学期" sortable></el-table-column>
					<el-table-column prop="name" label="名称"></el-table-column>
					<el-table-column prop="num" label="人数" sortable></el-table-column>
					<el-table-column label="操作" width="100">
						<template slot-scope="scope">
							<el-button @click="handleClick(scope.row)" type="text" size="small">学生名单</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-right">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="currentPage1"
						:page-size="10"
						layout="total, prev, pager, next"
						:total="3"
					></el-pagination>
				</div>
				<el-dialog :title="el_dialog__title" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
					<div class="u-f-right" style="padding: 15px 0;">
						<el-input v-model="key" size="mini" style="width: 260px;margin-right: 10px;" placeholder="输入名称搜索"></el-input>
						<el-button type="primary" size="mini">导出</el-button>
					</div>
					<el-table :data="tableData1" style="width: 100%;" :default-sort="{ prop: 'date', order: 'descending' }">
						<el-table-column prop="id" label="序号"></el-table-column>
						<el-table-column prop="year" label="学籍号"></el-table-column>
						<el-table-column prop="term" label="班级"></el-table-column>
						<el-table-column prop="name" label="姓名"></el-table-column>
					</el-table>
					<span slot="footer" class="dialog-footer">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="currentPage1"
							:page-size="10"
							layout="total, prev, pager, next"
							:total="3"
						></el-pagination>
					</span>
				</el-dialog>
			</template>
			<template v-if="tabIndex == 2">
				<div class="switching" v-if="list.length">
					<div class="switching-item" v-for="(item,index) in list" :key="index">
						<div class="switching-title">调课</div>
						<!-- <div class="">高二年级排课表</div> -->
						<div class="switching-item-list" style="margin-top: 20px;">
							<div class="raw">
								<span style="color: #8C8C8C;">原安排</span>
								<span>{{item.class_name}}</span>
								<span style="color: #8C8C8C;">|</span>
								<span>{{item.attend_day}}{{item.node}}</span>
							</div>
							<div class="adjust">
								<span style="color: #8C8C8C;">调整为</span>
								<span>{{item.group.class_name}}</span>
								<span style="color: #8C8C8C;">|</span>
								<span>{{item.group.attend_day}}{{item.node}}</span>
							</div>
						</div>
						<div class="u-f-right" style="margin-top: 15px;">调整人：{{item.teacher_name}} {{$common.timeToDate(item.createtime)}}</div>
					</div>
				</div>
				<el-empty :image-size="100" v-if="!list.length" style="margin-top: 150px;"></el-empty>
			</template>
			<template v-if="tabIndex == 5">
				<div class="font_blod" style="padding-bottom: 20px;">共7条数据</div>
				<el-table :data="tableData2" style="width: 100%" :default-sort="{ prop: 'date', order: 'descending' }">
					<el-table-column prop="id" label="序号" width="100"></el-table-column>
					<el-table-column prop="year" label="学年" sortable></el-table-column>
					<el-table-column prop="term" label="学期" sortable></el-table-column>
					<el-table-column prop="name" label="考试名称"></el-table-column>
					<el-table-column prop="num" label="段数" sortable></el-table-column>
					<el-table-column label="操作" width="100">
						<template slot-scope="scope">
							<el-button @click="handleClick1(scope.row)" type="text" size="small">详情</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-right">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="currentPage1"
						:page-size="10"
						layout="total, prev, pager, next"
						:total="3"
					></el-pagination>
				</div>
				<el-dialog :title="el_dialog__title1" :visible.sync="dialogVisible1" width="30%" :before-close="handleClose">
					<div class="details-list">
						<div class="details-item" v-for="(item, index) in 4" :key="index">
							<div class="details-title">时段{{ index + 1 }}</div>
							<div class="details-content">
								<div class="div1">2020-09-11 14:00-15:15</div>
								<div class="div2">
									走班考试
									<span>（39名考生）</span>
								</div>
							</div>
						</div>
					</div>
				</el-dialog>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		Info: {
			type: Object,
			default: {}
		}
	},
	data() {
		//  { name: '选修' }, { name: '社团' },, { name: '监考时段' }
		return {
			key: '',
			tabbars: [{ name: '岗位统计' }, { name: '课时统计' }, { name: '调课记录' }],
			tabIndex: 0,
			currentPage1: 10,
			tableData: [],
			tableData1: [],
			tableData2: [],
			dialogVisible: false,
			el_dialog__title: '',
			el_dialog__title1: '',
			dialogVisible1: false,
			eduWoek:[],
			tclass:[],
			grade:[],
			group:[],
			list:[],
			total:0,
			total4:0,
			page:1,
			limit:10
		};
	},
	created() {},
	mounted() {
		this.getjobWork();
		this.geteduWork();
		this.getList();
		if(this.$route.query.i){
			this.tabIndex =this.$route.query.i; 
		}
	},
	methods: {
		getList(){
			this.$api.rule.getCourseConfigList({
				teacher_id:this.$route.query.id
			}).then(res=>{
				if(res.data.code==1){
					this.list = res.data.data.rows;
				}
			})
		},
		// 学年筛选
		filteryear(row, column){
			return row.year;
		},
		// 学期筛选
		filterterm(row, column){
			return row.term;
		},
		// 性质筛选
		filternature(row, column){
			return row.nature;
		},
		// 学科筛选
		filtersubject(row, column){
			return row.subject;
		},
		tabTap(index) {
			this.tabIndex = index;
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.page = val;
			this.geteduWork()
		},
		handleClick(row) {
			this.dialogVisible = true;
			this.el_dialog__title = row.name;
		},
		handleClick1(row) {
			this.dialogVisible1 = true;
			this.el_dialog__title1 = row.name;
		},
		handleClose(done) {
			done();
		},
		// 岗位统计
		getjobWork(){
			console.log(this.Info)
			this.$api.teachers.jobWork({
				teacher_id:this.$route.query.id,
				year_id:this.$route.query.year_id,
				semester_id: this.Info.semester
			}).then(res => {
				if (res.data.code == 1) {
					this.tclass = res.data.data.class;
					this.grade = res.data.data.grade;
					this.group = res.data.data.group;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 教学岗位、
		geteduWork(){
			this.$api.teachers.courseamount({
				campusid:this.$route.query.campus_id,
				teacher_id:this.$route.query.id,
				page:this.page,
				limit:this.limit
			}).then(res=>{
				if(res.data.code==1){
					this.eduWoek = res.data.data.rows;
					this.total4 = res.data.data.total;
				}
			})
		},
		// 调课记录
		eduWork(){
			this.$api.teachers.eduWork({
				
			})
		}
	}
};
</script>

<style lang="scss">
.station {
	.nav-tab-bar {
		background-color: #ffffff;
		padding: 0.67vw;
		border-bottom: 1px solid #eeeeee;
		.nav-tab-item {
			color: #6e7a87;
			padding: 0 0.78125vw;
			cursor: pointer;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		&div:last-child {
			border-right: none;
		}
		.active {
			color: #63acff;
			position: relative;
			font-weight: bold;
		}
		.active::after {
			position: absolute;
			content: '';
			height: 0.1vw;
			left: 0.78125vw;
			right: 0.78125vw;
			background-color: #63acff;
			bottom: -0.67vw;
		}
	}
	.contents {
		background-color: #ffffff;
		height: 70vh;
		padding: 20px;
		.item {
			background-color: #f7f7f7;
			height: 160px;
			padding: 15px 20px;
			img {
				width: 12%;
			}
			span {
				padding-left: 10px;
			}
			.list {
				color: #858d96;
				margin-top: 15px;
				.icon-yuandian {
					margin-right: 5px;
					color: #55b0fe;
					font-size: 0.6vw !important;
				}
			}
		}
		.switching {
			padding-left: 20px;
			.icon-yuandian {
				font-size: 12px;
				color: #2d8cf0;
				margin-right: 5px;
			}
			.switching-item {
				margin-top: 20px;
				background-color: #fafafa;
				padding: 10px 15px 10px 60px;
				position: relative;
				color: #555555;
				.switching-title {
					background-color: #2d8cf0;
					color: #ffffff;
					position: absolute;
					left: 0;
					top: 0;
					padding: 3px 10px;
					border-bottom-right-radius: 15px;
				}
				.switching-item-list {
					div {
						padding-top: 10px;
						span {
							margin-right: 10px;
						}
					}
					.raw {
						position: relative;
					}
					.raw::before {
						position: absolute;
						content: '';
						width: 5px;
						height: 15px;
						background-color: #2d8cf0;
						left: -20px;
						top: 12px;
					}
				}
			}
		}
		.details-list {
			padding: 20px 0;
			.details-item {
				position: relative;
				background-color: #fafafa;
				padding: 30px 15px 15px 60px;
				margin-bottom: 15px;
				.details-title {
					background-color: #2d8cf0;
					color: #ffffff;
					position: absolute;
					left: 0;
					top: 0;
					padding: 3px 10px;
					border-bottom-right-radius: 15px;
				}
				.details-content {
					padding-left: 15px;
					color: #464646;
					.div1 {
						position: relative;
					}
					.div1::after {
						content: '';
						position: absolute;
						width: 3px;
						height: 15px;
						background-color: #2d8cf0;
						left: -15px;
					}
					.div2 {
						margin-top: 10px;
						span {
							color: #9d9d9d;
						}
					}
				}
			}
		}
	}
	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px;
	}
	.el-table__column-filter-trigger{
		.el-icon-arrow-down:before{
			content: '\E790';
			font-size: 16px;
		}
	}
}
</style>
