<template>
	<div class="logSheet" style="margin-top: 20px;">
		<!-- <div class="nav-tab-bar u-f-item">
			<div class="font_14 nav-tab-item" @click="tabTap(index)" :class="index == tabIndex ? 'active' : ''" v-for="(item, index) in tabbars" :key="index">{{ item.name }}</div>
		</div> -->
		<div class="contents">
				<div class="font_blod" style="color: #484848;padding-bottom: 15px;">共{{total}}条数据</div>
				<el-table ref="multipleTable" :data="logList" tooltip-effect="dark" style="width: 100%">
					<el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
					<el-table-column prop="grade_name" label="年级" align="center"></el-table-column>
					<el-table-column prop="class_name" label="班级" align="center"></el-table-column>
					<el-table-column prop="student_name" label="学生" align="center"></el-table-column>
					<el-table-column prop="category_text" label="学生评价" align="center"></el-table-column>
					<el-table-column label="性质" align="center">
						<template slot-scope="scope">
							<span class="nature" v-if="scope.row.type==1">正面</span>
							<span class="nature" v-if="scope.row.type==2">负面</span>
						</template>
					</el-table-column>
					<el-table-column prop="reason" label="说明" align="center"></el-table-column>
					<el-table-column prop="logTime" label="记录时间" align="center">
						<template slot-scope="scope">
							<div>{{$common.timeToDate(scope.row.createtime)}}</div>
						</template>
					</el-table-column>
				</el-table>
		</div>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			tabbars: [
				{ name: '过程性',id:1},
				{ name: '选修',id:2},
				{ name: '社团',id:3},
				{ name: '小组学习',id:4}
			],
			tabIndex: 0,
			logList:[],
			total:0
		};
	},
	created() {},
	mounted() {
		this.studentPatrol()
	},
	methods: {
		tabTap(index) {
			this.tabIndex = index;
			this.studentPatrol()
		},
		// 岗位统计
		studentPatrol(){
			let data = {
				category:this.tabbars[this.tabIndex].id,
				teacher_id:this.$route.query.id
			}
			this.$api.teachers.studentPatrol(data).then(res => {
				if (res.data.code == 1) {
					this.logList = res.data.data.rows;
					this.total = res.data.data.total;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
	}
};
</script>

<style lang="scss">
.logSheet {
	.nav-tab-bar {
		background-color: #ffffff;
		padding: 0.67vw;
		border-bottom: 1px solid #eeeeee;
		.nav-tab-item {
			color: #6e7a87;
			padding: 0 0.78125vw;
			cursor: pointer;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		&div:last-child {
			border-right: none;
		}
		.active {
			color: #63acff;
			position: relative;
			font-weight: bold;
		}
		.active::after {
			position: absolute;
			content: '';
			height: 0.1vw;
			left: 0.78125vw;
			right: 0.78125vw;
			background-color: #63acff;
			bottom: -0.67vw;
		}
	}
	.contents {
		background-color: #ffffff;
		height: 73vh;
		padding: 20px;
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.nature {
			background-color: #ffe1e1;
			color: #da4444;
			padding: 4px 15px;
		}
		.main-tab-bars {
			border: 1px solid #dadfe0;
			.main-tab-item {
				height: 30px;
				line-height: 30px;
				width: 100px;
				text-align: center;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			.active {
				color: #67adf4;
				background-color: #e1f3ff;
				border: 1px solid #67adf4;
			}
		}
	}
	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px;
	}
}
</style>
