<template>
	<div class="Survey" style="margin-top: 20px;">
		<div class="u-f-item u-f-jsb" style="padding-bottom: 15px;">
			<div class="font_blod" style="color: #5D5D5D;">共7条数据</div>
			<div><el-input placeholder="输入考试名称搜索" size="small"></el-input></div>
		</div>
		<el-table :data="tableData" @selection-change="handleSelectionChange" style="width: 100%" :default-sort = "{prop: 'date', order: 'descending'}">
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column prop="id" label="序号"></el-table-column>
			<el-table-column prop="names" label="名称"></el-table-column>
			<el-table-column prop="cycle" label="反馈周期"  :filters="[{ text: '每天', value: '1' }, { text: '单次', value: '2' }]" :filter-method="filterterm"></el-table-column>
			<el-table-column prop="workload" label="周工作量" sortable></el-table-column>
			<el-table-column label="操作" width="55">
				<template slot-scope="scope">
					<el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">查看</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="u-f-right">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page.sync="currentPage1"
				:page-size="10"
				layout="total, prev, pager, next"
				:total="3"
			></el-pagination>
		</div>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			multipleSelection: [],
			currentPage1: 10,
			tableData: [
				{
					id:1,
					names:'优秀教师评选记录',
					cycle:'每天',
					workload:'2021-03-26 15:35'
				},
				{
					id:2,
					names:'优秀教师评选记录',
					cycle:'每天',
					workload:'2021-03-26 15:35'
				},
				{
					id:3,
					names:'优秀教师评选记录',
					cycle:'每天',
					workload:'2021-03-26 15:35'
				}
			]
		};
	},
	created() {},
	mounted() {},
	methods: {
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		// 反馈周期预选
		filterterm(row, column){
			return row.cycle;
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
		},
	}
};
</script>

<style lang="scss">
.Survey {
	background-color: #ffffff;
	height: 75vh;
	margin-top: 20px;
	padding: 15px 20px;
	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table__column-filter-trigger{
		.el-icon-arrow-down:before{
			content: '\E790';
			font-size: 16px;
		}
	}
}
</style>
