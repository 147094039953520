<template>
	<div class="record" style="margin-top: 20px;">
		<div class="nav-tab-bar u-f-item">
			<div class="font_14 nav-tab-item" @click="tabTap(index)" :class="index == tabIndex ? 'active' : ''" v-for="(item, index) in tabbars" :key="index">{{ item.name }}</div>
		</div>
		<div class="contents">
			<template v-if="tabIndex == 0">
				<!-- <div class="u-f-item">
					<div style="margin-right: 20px;">
						<Progress strokeColor="#2D8CF0" :transitionDuration="1000" :radius="50" :strokeWidth="15" value="15">
						  <div style="font-size:26px;color: #2D8CF0;font-weight: 100;">
							  1<span style="font-size: 12px;">份</span>
						  </div>
						  <template v-slot:footer>
								<div class="u-f-justify" style="color: #94A3AB;">身份信息</div>
						  </template>
						</Progress>
					</div>
					<div style="margin-right: 20px;">
						<Progress strokeColor="#13C2C2" :transitionDuration="1000" :radius="50" :strokeWidth="15" value="15">
						  <div style="font-size:26px;color: #13C2C2;font-weight: 100;">
							  1<span style="font-size: 12px;">份</span>
						  </div>
						  <template v-slot:footer>
								<div class="u-f-justify" style="color: #94A3AB;">个人证明</div>
						  </template>
						</Progress>
					</div>
					<div style="margin-right: 20px;">
						<Progress strokeColor="#2D8CF0" :transitionDuration="1000" :radius="50" :strokeWidth="15" value="15">
						  <div style="font-size:26px;color: #2D8CF0;font-weight: 100;">
							  1<span style="font-size: 12px;">份</span>
						  </div>
						  <template v-slot:footer>
								<div class="u-f-justify" style="color: #94A3AB;">其它</div>
						  </template>
						</Progress>
					</div>
					<div style="padding-left: 30px;">
						<div style="font-size: 34px;color: #646F74;margin-bottom: 20px;">3份</div>
						<div style="color: #93999D;">共3份，身份信息1份，个人证明1份，其它1份</div>
					</div>
				</div> -->
				<div class="u-f-item u-f-jsb">
					<div class="font_blod" style="color: #484848;padding: 15px; 0">共{{ RecordList.length }}条数据</div>
					<el-button type="primary" size="mini" v-if="isbnt" @click="openupdateFile">上传文件</el-button>
				</div>

				<el-table ref="multipleTable" :data="RecordList" tooltip-effect="dark" style="width: 100%">
					<!-- <el-table-column type="selection" width="55" align="center"></el-table-column> -->
					<el-table-column label="序号" type="index" width="150" align="center"></el-table-column>
					<el-table-column label="文件名" align="center">
						<template slot-scope="scope">
							<div class="u-f-justify" style="cursor: pointer;">
								<img
									src="/static/image/excel.png"
									v-if="scope.row.file.type == 'excel' || scope.row.file.type == 'xls' || scope.row.file.type == 'csv'"
									style="width: 20px;margin-right: 5px;"
								/>
								<img src="/static/image/pdf2.png" v-if="scope.row.file.type == 'pdf'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/word.png" v-if="scope.row.file.type == 'docx' || scope.row.file.type == 'doc'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/img.png" v-if="scope.row.file.type == 'img'" style="width: 20px;margin-right: 5px;" />
								<div>{{ scope.row.file.name }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="size" label="大小" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.file.size">{{ scope.row.file.size }}kb</span>
						</template>
					</el-table-column>
					<el-table-column label="类型" align="center">
						<template slot-scope="scope">
							<div v-if="scope.row.type == 1">个人证明</div>
							<div v-if="scope.row.type == 2">身份证明</div>
							<div v-if="scope.row.type == 3">其它</div>
						</template>
					</el-table-column>
					<el-table-column prop="year" label="获取年份" align="center"></el-table-column>
					<el-table-column prop="upTime" label="上传时间" align="center">
						<template slot-scope="scope">
							{{ scope.row.createtime | getLocalTime }}
						</template>
					</el-table-column>
					<el-table-column align="center" label="操作" width="150">
						<template slot-scope="scope">
							<el-button @click="download(scope.row)" type="text" size="small">下载</el-button>
							<!-- <el-button @click="delcourse(scope.row.id, scope.row.time_id)" type="text" size="small" style="color:red;">撤回</el-button> -->
						</template>
					</el-table-column>
					<!-- <el-table-column prop="audit_name" label="审核人" align="center"></el-table-column>
					<el-table-column prop="audit_time" label="审核时间" align="center"></el-table-column> -->
				</el-table>
				<!-- <el-button type="primary" size="small" style="margin-top: 10px;">下载文件</el-button> -->
			</template>
			<template v-if="tabIndex == 1">
				<div class="u-f-item u-f-jsb">
					<div class="font_blod" style="color: #484848;padding: 15px; 0">共{{ HonorList.length }}条数据</div>
					<el-button type="primary" size="mini" v-if="isbnt" @click="addVisible2=true">上传文件</el-button>
				</div>
				<el-table ref="multipleTable1" :data="HonorList" tooltip-effect="dark" style="width: 100%">
					<el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
					<el-table-column label="文件名" align="center" width="180">
						<template slot-scope="scope">
							<div class="u-f-justify">
								<img
									src="/static/image/excel.png"
									v-if="scope.row.file.type == 'excel' || scope.row.file.type == 'xls' || scope.row.file.type == 'csv'"
									style="width: 20px;margin-right: 5px;"
								/>
								<img src="/static/image/pdf2.png" v-if="scope.row.file.type == 'pdf'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/word.png" v-if="scope.row.file.type == 'docx' || scope.row.file.type == 'doc'" style="width: 20px;margin-right: 5px;" />
								<img src="/static/image/img.png" v-if="scope.row.file.type == 'img'" style="width: 20px;margin-right: 5px;" />
								<div>{{ scope.row.file.name }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="size" label="大小" align="center"></el-table-column>
					<el-table-column prop="job_title_type" label="职称类别" align="center">
						<template slot-scope="scope">
							<div v-if="scope.row.job_title_type == 1">职称</div>
							<div v-if="scope.row.job_title_type == 2">获奖</div>
							<div v-if="scope.row.job_title_type == 3">论文</div>
							<div v-if="scope.row.job_title_type == 4">证明</div>
						</template>
					</el-table-column>
					<el-table-column prop="job_title" label="职称名称"  align="center"></el-table-column>
					<el-table-column prop="is_official" label="性质"  align="center">
						<template slot-scope="scope">
							<div v-if="scope.row.is_official == 1">官方</div>
							<div v-if="scope.row.is_official == 2">非官方</div>
						</template>
					</el-table-column>
					<el-table-column prop="level" label="级别" align="center">
						<template slot-scope="scope">
							<div v-if="scope.row.level == 1">国</div>
							<div v-if="scope.row.level == 2">省</div>
							<div v-if="scope.row.level == 3">市</div>
							<div v-if="scope.row.level == 4">区</div>
							<div v-if="scope.row.level == 5">校</div>
							<div v-if="scope.row.level == 6">其它</div>
						</template>
					</el-table-column>
					<el-table-column prop="issue_time" label="获奖时间"  align="center"></el-table-column>
					<el-table-column prop="issue_unit" label="授予单位"  align="center"></el-table-column>
					<el-table-column prop="remark" label="备注"  align="center"></el-table-column>
					<el-table-column label="上传时间"  align="center">
						<template slot-scope="scope">
							{{ scope.row.createtime | getLocalTime }}
						</template>
					</el-table-column>
					<el-table-column align="center" label="操作" width="150">
						<template slot-scope="scope">
							<el-button @click="download(scope.row)" type="text" size="small">下载</el-button>
							<!-- <el-button @click="delcourse(scope.row.id, scope.row.time_id)" type="text" size="small" style="color:red;">撤回</el-button> -->
						</template>
					</el-table-column>
				</el-table>
				<!-- <el-button type="primary" size="small" style="margin-top: 10px;">下载文件</el-button> -->
			</template>
		</div>

		<el-dialog title="上传文件" :visible.sync="addVisible" width="500px" :before-close="handleClose">
			<div class="edit-box">
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 5px;">
						<span>*</span>
						文件
					</div>
					<div>
						<el-button size="mini" style="position: relative;">
							<input ref="filElem" type="file" class="upload-file" @change="getFile" />
							点击上传
						</el-button>
						<div class="u-f-item u-f-jsb file_box" v-if="file.name" style="margin-top: 10px;width: 300px;">
							<div class="u-f-item">
								<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>
								{{ file.name }}
							</div>
							<i class="el-icon-circle-check" style="color: #67C23A;"></i>
							<i class="el-icon-circle-close" style="color: #67C23A;" @click="delFile"></i>
						</div>
					</div>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						类型
					</div>
					<el-radio-group v-model="type">
						<el-radio-button label="1">个人证明</el-radio-button>
						<el-radio-button label="2">身份信息</el-radio-button>
						<el-radio-button label="3">其它</el-radio-button>
					</el-radio-group>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						获取年份
					</div>
					<el-date-picker v-model="year" type="year" value-format="yyyy" format="yyyy" placeholder="选择年" style="width: 60%;"></el-date-picker>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="closeVisible" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="submit">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="上传文件" :visible.sync="addVisible2" width="600px" :before-close="handleClose">
			<div class="edit-box">
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						职称类别
					</div>
					<el-select v-model="job_title_type" placeholder="请选择"  style="width: 70%;">
						<el-option v-for="item in jobList" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						职称名称
					</div>
					<el-input v-model="job_title" placeholder="请输入职称名称"  style="width: 70%;"></el-input>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 5px;">
						<span>*</span>
						文件
					</div>
					<div>
						<el-button size="mini" style="position: relative;">
							<input ref="filElem1" type="file" class="upload-file" @change="getFile1" />
							点击上传
						</el-button>
						<div class="u-f-item u-f-jsb file_box" v-if="file1.name" style="margin-top: 10px;width: 300px;">
							<div class="u-f-item">
								<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>
								{{ file1.name }}
							</div>
							<i class="el-icon-circle-check" style="color: #67C23A;"></i>
							<i class="el-icon-circle-close" style="color: #67C23A;" @click="delFile1"></i>
						</div>
					</div>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						是否官方
					</div>
					<el-radio-group v-model="is_official">
						<el-radio :label="1">是</el-radio>
						<el-radio :label="2">否</el-radio>
					</el-radio-group>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						获取时间
					</div>
					<el-date-picker v-model="issue_time" type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd" placeholder="请选择" style="width: 70%;"></el-date-picker>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						荣誉级别
					</div>
					<el-radio-group v-model="level">
						<el-radio :label="1">国</el-radio>
						<el-radio :label="2">省</el-radio>
						<el-radio :label="3">市</el-radio>
						<el-radio :label="4">区</el-radio>
						<el-radio :label="5">校</el-radio>
						<el-radio :label="6">其它</el-radio>
					</el-radio-group>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						授予单位
					</div>
					<el-input v-model="issue_unit" placeholder="请输入授予单位"  style="width: 70%;"></el-input>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 5px;">备注</div>
					<el-input v-model="remark" type="textarea" :rows="5" placeholder="请输入授予单位"  style="width: 70%;"></el-input>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="closeVisible" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="submit1">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import Progress from 'easy-circular-progress';
export default {
	components: {
		Progress
	},
	props: {},
	data() {
		return {
			key: '',
			tabbars: [{ name: '个人档案' }, { name: '专业化发展档案' }],
			tabIndex: 0,
			multipleSelection: [],
			multipleSelection2: [],
			RecordList: [],
			HonorList: [],
			isbnt: false,
			addVisible: false,
			type: 1,
			file: '',
			fileUrl: '',
			year: '',
			files: {
				url: '',
				type: '',
				name: '',
				size: ''
			},
			addVisible2: false,
			job_title_type:'',
			job_title:'',
			jobList:[
				{value: '1',label: '职称'},
				{value: '2',label: '获奖'},
				{value: '3',label: '论文'},
				{value: '4',label: '证明'}
			],
			issue_unit:'',
			is_official:1,
			level:1,
			remark:'',
			file1: '',
			files1: {
				url: '',
				type: '',
				name: '',
				size: ''
			},
			issue_time:''
		};
	},
	filters: {
		getLocalTime(nS) {
			return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
		}
	},
	created() {},
	mounted() {
		if (this.$route.query.id == window.localStorage.getItem('id')) {
			this.isbnt = true;
		}
		this.teacherRecord();
		this.teacherHonor();
	},
	methods: {
		tabTap(index) {
			this.tabIndex = index;
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		handleSelectionChange2(val) {
			this.multipleSelection2 = val;
		},
		// 个人档案列表
		teacherRecord() {
			this.$api.teachers
				.teacherRecord({
					teacher_id: this.$route.query.id,
					campus_id: this.$route.query.campus_id
				})
				.then(res => {
					if (res.data.code == 1) {
						this.RecordList = res.data.data.rows;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 专业化文当列表
		teacherHonor() {
			this.$api.teachers
				.teacherHonor({
					teacher_id: this.$route.query.id,
					campus_id: this.$route.query.campus_id
				})
				.then(res => {
					if (res.data.code == 1) {
						this.HonorList = res.data.data.rows;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		getFile(e) {
			if (this.$refs.filElem.files.length) {
				this.file = this.$refs.filElem.files[0];
				console.log(this.file);
				let myfile = this.$refs.filElem;
				let files = myfile.files;
				let file = files[0];
				var formData = new FormData();
				formData.append('file', file);
				this.$api.setting.uploadFile(formData).then(res => {
					if (res.data.code == 1) {
						this.files.url = res.data.data.url;
						this.files.type = this.file.name.substring(file.name.indexOf('.') + 1).toLowerCase();
						this.files.name = this.file.name.substring(0, file.name.indexOf('.'));
						this.files.size = this.file.size;
					} else {
						this.$message.error(res.data.msg);
					}
				});
			}
		},
		delFile() {
			this.file = '';
			this.$refs.filElem.value = '';
			this.files.url = '';
			this.files.type = '';
			this.files.name = '';
			this.files.size = '';
		},
		getFile1(e) {
			if (this.$refs.filElem1.files.length) {
				this.file1 = this.$refs.filElem1.files[0];
				console.log(this.file1);
				let myfile = this.$refs.filElem1;
				let files = myfile.files;
				let file = files[0];
				var formData = new FormData();
				formData.append('file', file);
				this.$api.setting.uploadFile(formData).then(res => {
					if (res.data.code == 1) {
						this.files1.url = res.data.data.url;
						this.files1.type = this.file1.name.substring(file.name.indexOf('.') + 1).toLowerCase();
						this.files1.name = this.file1.name.substring(0, file.name.indexOf('.'));
						this.files1.size = this.file1.size;
					} else {
						this.$message.error(res.data.msg);
					}
				});
			}
		},
		delFile1() {
			this.file1 = '';
			this.$refs.filElem1.value = '';
			this.files1.url = '';
			this.files1.type = '';
			this.files1.name = '';
			this.files1.size = '';
		},
		// 关闭弹窗
		handleClose(done) {
			done();
		},
		closeVisible() {
			this.info();
			this.addVisible = false;
			this.addVisible2 = false;
		},
		openupdateFile() {
			this.addVisible = true;
		},
		submit() {
			let data = {
				file: this.files,
				type: this.type,
				year: this.year
			};
			this.$api.teachers.teacherRecordAdd(data).then(res => {
				if (res.data.code == 1) {
					this.$message.success('上传成功');
					this.teacherRecord();
					this.closeVisible();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		download(e) {
			location.href = 'http://hlgzx.zhiyunapi.com/' + e.file.url;
		},
		submit1() {
			let data = {
				level:this.level,
				is_official:this.is_official,
				issue_time:this.issue_time,
				file:this.files1,
				job_title_type:this.job_title_type,
				job_title:this.job_title,
				issue_unit:this.issue_unit
			};
			if(this.remark){data.remark = this.remark}
			this.$api.teachers.teacherHonorAdd(data).then(res => {
				if (res.data.code == 1) {
					this.$message.success('上传成功');
					this.teacherHonor();
					this.closeVisible();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		info(){
			this.delFile1();
			this.delFile();
			this.type = 1;
			this.year = '';
			this.level = 1;
			this.is_official = 1;
			this.issue_time = '';
			this.job_title_type = '';
			this.job_title = '';
			this.issue_unit = '';
			this.remark = ''
		}
	}
};
</script>

<style lang="scss">
.record {
	.nav-tab-bar {
		background-color: #ffffff;
		padding: 0.67vw;
		border-bottom: 1px solid #eeeeee;
		.nav-tab-item {
			color: #6e7a87;
			padding: 0 0.78125vw;
			cursor: pointer;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		&div:last-child {
			border-right: none;
		}
		.active {
			color: #63acff;
			position: relative;
			font-weight: bold;
		}
		.active::after {
			position: absolute;
			content: '';
			height: 0.1vw;
			left: 0.78125vw;
			right: 0.78125vw;
			background-color: #63acff;
			bottom: -0.67vw;
		}
	}
	.contents {
		background-color: #ffffff;
		height: 70vh;
		padding: 15px 20px 20px 20px;
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		max-height: 65vh;
		overflow-y: scroll;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.el-radio{
					margin-right: 20px;
				}
			}
			.upload-file {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.vue-circular-progress .circle__progress {
	stroke-linecap: square;
}
</style>
